.footer {
  display: flex;
  justify-content: space-between; /* Ensure the items are spaced out */
  align-items: center; /* Vertically center the items */
  padding: 0.5rem;
  color: #627580;
  font-size: 0.8rem;
  margin-top: auto;
}

.left {
  flex-grow: 1; /* Allows the text to take up remaining space */
  text-align: left;
}

.textFoot {
  margin: 0.3rem 0;
}

.right {
  display: flex;
  justify-content: flex-end;
}

.socialMedia {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.5rem;
}

@media only screen and (max-width: 767px) {
  .footer {
    flex-direction: column-reverse;
    gap: 1rem;
  }
}
