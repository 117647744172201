/* * {
  box-sizing: border-box;
} */

:root {
  --text-night-mode: #e0e6ea;
  --bg-night-mode: #000;
  --text-day-mode: #3a4a54;
  --bg-day-mode: #ffffff; /* Fundal alb */
}

body {
  margin: 0;
 
  font-size: 1.2rem;
  font-weight: 500;
  font-family: "Arial", sans-serif;

}

.darkTheme {
  background-color: var(--bg-night-mode);
  color: var(--text-night-mode);
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-attachment: fixed;
  padding: 0 0.5rem;
  overflow-x: hidden;
}

.lightTheme {
  background-color: var(--bg-day-mode); /* Alb fix */
  color: var(--text-day-mode);
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-attachment: fixed;
  padding: 0 0.5rem;
  overflow-x: hidden;
}

@media (max-width: 767px) {
  body {
    background-attachment: scroll;
    overflow-x: hidden;
  }
}
