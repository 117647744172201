.backToTopContainer {
  position: fixed;
  bottom: 4rem;
  right: 2rem;
  z-index: 10000;
}

.backToTopButton {
  padding: 0.5rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #3747d6;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: none;
  transition: background-color 0.3s ease;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0 10px 20px rgba(55, 71, 214, 0.3); */
}

.backToTopButton:hover {
  background-color: #fff;
  /* border: 2px solid #3747d6; */
  color: #3747d6;
  box-shadow: 0 4px 12px rgba(55, 71, 214, 0.3);
  transition: all 0.2s ease-in-out; 
}

.backToTopButton:focus {
  ring: 2px solid #2d3748;
  ring-opacity: 50%;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

@media (max-width: 768px) {
  .backToTopContainer {
    bottom: 2rem;
    right: 2rem;
  }

  .bottomAtEnd {
    bottom: 3rem;
  }
}
